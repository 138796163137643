<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
                class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
        <div class="mt-6 flex items-center justify-between px-6">
            <h4>Editar promoción</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
        </div>
        <vs-divider class="mb-0"></vs-divider>

        <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
            <div class="p-6">
                <!-- Name -->
                <vs-input @blur="promotion.name= trimString(promotion.name)" label="Nombre" v-model="promotion.name"
                          class="w-full" name="name"
                          v-validate="'required|min:1|max:30'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('name')">{{ errors.first('name') }}</span>

                <!-- Description -->
                <vs-input @blur="promotion.description= trimString(promotion.description)" label="Descripción"
                          v-model="promotion.description"
                          class="w-full  mt-5" name="description"
                          v-validate="'required|min:1|max:100'"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('description')">{{ errors.first('description') }}</span>

                <!-- Start  date-->
                <div class="mt-5">
                    <label class="vs-select--label" for="startDate">Fecha de inicio</label>
                    <datepicker label="Fecha de inicio" v-model="promotion.startDate" id="startDate"
                                v-validate="'required'" name="startDate"></datepicker>
                    <span class="text-danger text-sm"
                          v-show="errors.has('startDate')">{{ errors.first('startDate') }}</span>
                </div>

                <!-- End date-->
                <div class="mt-5">
                    <label class="vs-select--label" for="endDate">Fecha de fin</label>
                    <datepicker label="Fecha de fin" v-model="promotion.endDate" id="endDate"
                                v-validate="'required'" name="endDate"></datepicker>
                    <span class="text-danger text-sm"
                          v-show="errors.has('endDate')">{{ errors.first('endDate') }}</span>
                </div>

                <!--Quantity-->
                <vs-input-number class="mt-5 mb-5" label="Cantidad:" v-model.number="promotion.quantity"
                                 name="quantity" v-validate="'required|min_value:0'" min="0"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
                <!--End-->
            </div>
        </component>

        <div class="flex flex-wrap items-center p-6" slot="footer">
            <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
            <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
            <div v-if="progress" style="margin-top: 1rem; width: 100%">
                <vs-progress indeterminate color="primary"></vs-progress>
            </div>
        </div>
    </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import Datepicker from 'vuejs-datepicker'
  import trimString from "../../mixins/trimString"
  import vSelect from 'vue-select'

  const firebase = require("firebase")
  // Required for side-effects
  require("firebase/firestore")
  let db = firebase.firestore()
  export default {
    name: 'UpdatePromotion',
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        }
      }
    },
    components: {
      VuePerfectScrollbar,
      Datepicker,
      vSelect
    },
    mixins: [trimString],
    data() {
      return {
        promotion: {
          quantity: 0,
          target: 'product'
        },
        typesOfPromotions: [
          {name: 'Producto gratis', value: 'free'},
          {name: 'Producto gratis por volumen', value: 'volume'},
          {name: 'Porcentaje de descuento', value: 'percentage'}
        ],
        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60
        },
        progress: false
      }
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        this.promotion = {
          ...this.data
        }
        this.$validator.reset()
      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
      },
      scrollbarTag() {
        return this.$store.getters.scrollbarTag
      }
    },
    methods: {
      submitData() {
        this.$validator.validateAll().then(async result => {
          if (result) {
            this.progress = true

            this.promotion.startDate = new Date(this.promotion.startDate.getFullYear() + "/" + this.promotion.startDate.getMonth() + 1 + "/" + this.promotion.startDate.getDate() + " 00:00:00")
            this.promotion.endDate = new Date(this.promotion.endDate.getFullYear() + "/" + this.promotion.endDate.getMonth() + 1 + "/" + this.promotion.endDate.getDate() + " 23:59:00")
            let obj = {
              ...this.promotion
            }
            delete obj.id
            delete obj.createdAt
            delete obj.state
            delete obj.used
            // Save in firebase
            db.collection('promotions').doc(this.promotion.id).update({
              ...obj,
              uid: firebase.auth().currentUser.uid,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp()
            }).then(() => {
              let obj = {
                ...this.promotion
              }
              this.$store.dispatch('promotions/updatePromotion', {promotion: obj})
              this.progress = false
              this.$vs.notify({
                color: 'success',
                title: 'Promoción',
                text: 'Promoción modificada correctamente.'
              })
              this.$emit('closeSidebar')
            }).catch((e) => {
              console.log(e)
              this.progress = false
              this.$vs.notify({
                color: 'warning',
                title: '¡Oh no!',
                text: 'Hubo un error.'
              })
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
    .add-new-data-sidebar {
        ::v-deep .vs-sidebar--background {
            z-index: 52010;
        }

        ::v-deep .vs-sidebar {
            z-index: 52010;
            width: 400px;
            max-width: 90vw;

            .img-upload {
                margin-top: 2rem;

                .con-img-upload {
                    padding: 0;
                }

                .con-input-upload {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .scroll-area--data-list-add-new {
        // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
        height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

        &:not(.ps) {
            overflow-y: auto;
        }
    }
</style>
