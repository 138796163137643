<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
                class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
        <div class="mt-6 flex items-center justify-between px-6">
            <h4>Detalles de promoción</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
        </div>
        <vs-divider class="mb-0"></vs-divider>

        <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
            <!--Free-->
            <div v-if="promotion.type.value==='free'" class="p-6">
                <!--Products-->
                <div>
                    <label class="vs-select--label mt-5" for="product">Selecciona producto</label>
                    <v-select id="product" label="label" :options="products"
                              name='product'
                              v-model="promotion.detail.product"
                              :dir="$vs.rtl ? 'rtl' : 'ltr'"
                              v-validate="'required'"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('product')">{{ errors.first('product') }}</span>
                </div>
                <!--End-->
                <!-- Amount -->
                <vs-input label="Monto mínimo de pedido" v-model="promotion.detail.amount"
                          class="w-full mt-5" name="amount"
                          v-validate="'required|min_value:0'" type="number"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('amount')">{{ errors.first('amount') }}</span>
                <!--End-->
            </div>
            <!--End free-->
            <!--Volume-->
            <div v-if="promotion.type.value==='volume'" class="p-6">
                <!--Products-->
                <div v-if="promotion.target==='product'">
                    <label class="vs-select--label mt-5" for="productVolume">Selecciona producto</label>
                    <v-select id="productVolume" label="label" :options="products"
                              name='product'
                              v-model="promotion.detail.product"
                              :dir="$vs.rtl ? 'rtl' : 'ltr'"
                              v-validate="'required'"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('product')">{{ errors.first('product') }}</span>
                </div>
                <!--End-->
                <!--Categories-->
                <div v-if="promotion.target==='category'">
                    <label class="vs-select--label mt-5" for="categoryVolume">Selecciona categoría</label>
                    <v-select id="categoryVolume" label="name" :options="categories"
                              name='category'
                              v-model="promotion.detail.category"
                              :dir="$vs.rtl ? 'rtl' : 'ltr'"
                              v-validate="'required'"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('category')">{{ errors.first('category') }}</span>
                </div>
                <!--End-->
                <!-- Amount -->
                <vs-input label="Posterior a" v-model="promotion.detail.quantity"
                          class="w-full mt-5" name="quantity"
                          v-validate="'required|min_value:0'" type="number"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
                <!--End-->
                <!-- Gift -->
                <vs-input label="Obsequio" v-model="promotion.detail.gift"
                          class="w-full mt-5" name="gift"
                          v-validate="'required|min_value:0'" type="number"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('gift')">{{ errors.first('gift') }}</span>
                <!--End-->
            </div>
            <!--Percentage-->
            <div v-if="promotion.type.value==='percentage'" class="p-6">
                <!--Products-->
                <div v-if="promotion.target==='product'">
                    <label class="vs-select--label mt-5" for="productPercentage">Selecciona producto</label>
                    <v-select id="productPercentage" label="label" :options="products"
                              name='product'
                              v-model="promotion.detail.product"
                              :dir="$vs.rtl ? 'rtl' : 'ltr'"
                              v-validate="'required'"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('product')">{{ errors.first('product') }}</span>
                </div>
                <!--End-->
                <!--Categories-->
                <div v-if="promotion.target==='category'">
                    <label class="vs-select--label mt-5" for="categoryPercentage">Selecciona categoría</label>
                    <v-select id="categoryPercentage" label="name" :options="categories"
                              name='category'
                              v-model="promotion.detail.category"
                              :dir="$vs.rtl ? 'rtl' : 'ltr'"
                              v-validate="'required'"/>
                    <span class="text-danger text-sm"
                          v-show="errors.has('category')">{{ errors.first('category') }}</span>
                </div>
                <!--End-->
                <!-- Quantity -->
                <vs-input label="Posterior a" v-model="promotion.detail.quantity"
                          class="w-full mt-5" name="quantity"
                          v-validate="'required|min_value:0'" type="number"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('quantity')">{{ errors.first('quantity') }}</span>
                <!--End-->
                <!-- Percentage -->
                <vs-input label="Porcentaje" v-model="promotion.detail.percentage"
                          class="w-full mt-5" name="percentage"
                          v-validate="'required|min_value:0'" type="number"/>
                <span class="text-danger text-sm"
                      v-show="errors.has('percentage')">{{ errors.first('percentage') }}</span>
                <!--End-->
            </div>
            <!---End volume-->
        </component>

        <div class="flex flex-wrap items-center p-6" slot="footer">
            <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
            <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
            <div v-if="progress" style="margin-top: 1rem; width: 100%">
                <vs-progress indeterminate color="primary"></vs-progress>
            </div>
        </div>
    </vs-sidebar>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import Datepicker from 'vuejs-datepicker'
  import trimString from "../../mixins/trimString"
  import vSelect from 'vue-select'

  const firebase = require("firebase")
  // Required for side-effects
  require("firebase/firestore")
  let db = firebase.firestore()
  export default {
    name: 'PromotionDetail',
    props: {
      isSidebarActive: {
        type: Boolean,
        required: true
      },
      data: {
        type: Object,
        default: () => {
        }
      },
      products: {
        type: Array,
        required: true
      },
      categories: {
        type: Array,
        required: true
      }
    },
    components: {
      VuePerfectScrollbar,
      Datepicker,
      vSelect
    },
    mixins: [trimString],
    data() {
      return {
        promotion: {
          type: {},
          detail: {}
        },
        settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60
        },
        progress: false
      }
    },
    watch: {
      isSidebarActive(val) {
        if (!val) return
        this.promotion = {
          ...this.data
        }
        if (!this.promotion.detail) {
          this.promotion.detail = {}
        }
        this.$validator.reset()
      }
    },
    computed: {
      isSidebarActiveLocal: {
        get() {
          return this.isSidebarActive
        },
        set(val) {
          if (!val) {
            this.$emit('closeSidebar')
          }
        }
      },
      isFormValid() {
        return !this.errors.any()
      },
      scrollbarTag() {
        return this.$store.getters.scrollbarTag
      }
    },
    methods: {
      submitData() {
        this.$validator.validateAll().then(async result => {
          if (result) {
            this.progress = true

            // Save in firebase
            let obj = {
              ...this.promotion
            }
            db.collection('promotions').doc(this.promotion.id).update({
              detail: obj.detail,
              uid: firebase.auth().currentUser.uid,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp()
            }).then(() => {
              let obj = {
                ...this.promotion
              }
              this.$store.dispatch('promotions/updatePromotion', {promotion: obj})
              this.progress = false
              this.$vs.notify({
                color: 'success',
                title: 'Promoción',
                text: 'Promoción modificada correctamente.'
              })
              this.$emit('closeSidebar')
            }).catch((e) => {
              console.log(e)
              this.progress = false
              this.$vs.notify({
                color: 'warning',
                title: '¡Oh no!',
                text: 'Hubo un error.'
              })
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
    .add-new-data-sidebar {
        ::v-deep .vs-sidebar--background {
            z-index: 52010;
        }

        ::v-deep .vs-sidebar {
            z-index: 52010;
            width: 450px;
            max-width: 90vw;

            .img-upload {
                margin-top: 2rem;

                .con-img-upload {
                    padding: 0;
                }

                .con-input-upload {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .scroll-area--data-list-add-new {
        // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
        height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

        &:not(.ps) {
            overflow-y: auto;
        }
    }
</style>
